import Lightbox from 'modules/gallery-lightbox';
import Gallery from 'modules/gallery';
import 'mypage/sticky-header';
import 'mypage/list';

import MyPageWebSockets from 'websockets/websocket.js';
MyPageWebSockets('team');

// fixing supporters layout if they enter a really long name
// not ideal, but can't come up with a good solution that doesn't
// involve rewriting a ton of CSS
$('#mypage__supporters li').each(function () {
  const $li = $(this);
  const $name = $li.find('.supporters__name');
  // add 8 extra pixels for breathing room
  const originalMargin = parseInt($li.css('margin-bottom'));
  const neededMargin = parseInt($name.height() + 12);
  if (neededMargin > originalMargin) {
    $li.css('margin-bottom', neededMargin);
  }
});

// non-editable team mypage stuff
// Safari hack for position view all link with participant photos
if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
  $('.view-all-button').addClass('safari-only');
}

const teamNameEl = $('#editable_team_name');
const myPageHeaderEl = $('#mypage__header');
const myPageHeaderGradEl = $('#mypage-header-gradient');
function sizeMobileHeader () {
  let height = 315;
  const titleHeight = teamNameEl.height();
  if ($('#is-mobile').is(':visible') && titleHeight > 167) {
    height += titleHeight - 197;
    myPageHeaderEl.css('padding-top', `${height}px`).css('background-size', `auto ${height - 30}px`);
    myPageHeaderGradEl.css('height', `${height - 30}px`);
  } else {
    myPageHeaderEl.css('padding-top', '').css('background-size', '');
    myPageHeaderGradEl.css('height', '');
  }
}

$(window).resize(sizeMobileHeader);
sizeMobileHeader();

Lightbox.lightboxID = '#mypage_photo_overlay';
Lightbox.photoID = '#mypage_photo_overlay_image';
Lightbox.setup();

Gallery.setup();
